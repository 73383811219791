import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layouts'

export default class Resume extends Component {
  state = {
    numPages: null,
  }
  onDocumentLoadSuccess = (doc) => {
    const { numPages } = doc
    this.setState({
      numPages,
    })
  }

  render() {
    const resumeUrl =
      this.props.data.allContentfulStatic.edges[0].node.image.file.url

    const queryString =
      '#toolbar=0&statusbar=0&messages=0&navpanes=0&scrollbar=0'

    return (
      <Layout>
        <div className="resume">
          <iframe
            src={`${resumeUrl}?${queryString}`}
            className="resume-iframe"
          />
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query resumeQuery {
    allContentfulStatic(filter: { title: { eq: "RESUME" } }) {
      edges {
        node {
          id
          title
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`
